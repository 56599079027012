body {
  background-color: #3D3D3C;
  width: 100vw;
  flex-direction: column;
  color: white
}

.App {
  text-align: center;
  margin-bottom: 24px;
  padding-bottom: 12px;
}

a {
  color: white;
  text-decoration: underline;
}


.algorithmContainer {
  text-align: center;
  margin-bottom: 24px;
  padding-bottom: 12px;
  vertical-align:middle;
  display: inline;
}

#algorithmCanvas {
  width: 80vw;
  height: 80vh;
  border: 2px solid cadetblue;
  border-radius: 20px;
  padding: 4px;
  margin: 4px;
  margin-inline: 6px;
}

.algorithmOptions {
  display: inline-block;
  align-items: center;
  vertical-align: middle;
}

.algorithmInputs {
  display: inline-block;
  list-style: none;
  font-size: 16px;
  margin-inline: 8px;
}

#algorithmSelect {
  border: 2px solid black;
  border-radius: 20px;
  background-color: grey;
  padding: 4px;
}

.dropDownTop {
  border-bottom: 2px solid white;
  padding: 2px;
}



#header {
  display: inline;
  margin: 8px;
  height: 36px;
  white-space: noWarp;
  margin-bottom: 36px;
  font-size: 24px;;
}

.header {
  top: 0;
  margin-top: 0px;
  color: white;
}

#left {
  float: left;
  position: absolute;
  display: inline;
  margin-left: 20px;
  left: 0;
  top: 0;
}

#right {
  display: inline;
  float: right;
  flex-direction: row-reverse;
  position:absolute;
  margin-right: 20px;
  width:fit-content;
  right: 0;
  top:0;
}

.rightList {
  display: inline-block;
  align-self: center;
  align-items: center;
  align-content: center;
  flex-direction: row-reverse;
  margin-inline: 12px;
}


#about {
  width: 100vw;
}

.leftRightContainer {
  display: inline-block;
  justify-content: space-between;
  max-width: 80vw;
  border: 2px dotted #61dafb;
  border-radius: 20px;
}

.leftDiv {

  position: relative;
  display: inline-block;
  flex-direction: column;
}

.aboutDes {
  width: auto;
  justify-content: space-evenly;
  font-size: 18px;
  border-radius: 20px;
  margin-inline: 8px;
  padding: 2px;
  padding-top: 12px;
  padding-bottom: 12px;
  background-color:cadetblue;
}

.rightDiv {
  position: relative;
  display: inline-block;
  align-content: center;
}

#proficiencyList {
  margin: 8px;
  padding: 2px;
  border: 2px solid cadetblue;
  border-radius: 20px;
  align-self: center;
  align-content: center;
  display:inline-block;
  flex-direction: column;
}

.proficiencyItems {
  margin-inline: 0px;
  width: 100px;
  height: 120px;
  display: inline-block;
  vertical-align: middle;
}

.proficiencyImageSmall {
  width:max-content;
  height: 100px;
}

.proficiencyImage {

  width: max-content;
  height: 120px;
}

#ReactLogo {
  translate: -16% ;
  width:max-content;
  height: 100px;
}

#ReactNativeLogo {
  translate: -16% ;
  width:max-content;
  height: 100px;
}

#HTMLLogo {
  translate: -8% ;
}

#SQLLogo {
  width: 100px;
}


#projectContainer {
  display: inline-block;
  width: 100vw;
}

.project {
  display: inline-block;
  border: 2px dotted #61dafb;
  border-radius: 20px;
  margin: 4px;
  align-self: center;
  width: 440px;
}

.project img {
  align-self: center;
  width: 360px;
  margin: 0px;
}

.projectDes {
  border-radius: 20px;
  margin-inline: 4px;
  padding-top: 12px;
  padding-bottom: 12px;
  background-color:cadetblue;
}


#Foot {
  align-self: center;
  align-items: center;
  background-color: blanchedalmond;
  height: 100%;
  width: 100vw;
  margin: 0px;
  padding: 0px;
  box-shadow: 0 50vh 0 50vh blanchedalmond;
}

.contact {
  align-items: center;
  list-style: none;
  vertical-align: middle;
  display: float;
}

.contacts {
  display: inline-block;
  align-content: center;
  text-align: center;
  vertical-align: middle;
  margin-inline: 8px;
  font-size: 20px;
}

.contacts label {
  display: block;
  font-size: 16px;
}

.contacts img {
  width: 60px;
}

#toTop {
  vertical-align: middle;
  text-align: center;
  align-content: center;
  font-size: 28px;
  float: right;
  margin-right: 48px;
}

#footerSpacer {
  color:#3D3D3C;
  font-size: 28px;
  vertical-align: middle;
  left: 0;
  display: flex;
  float: left;
  margin-left: 48px;
}


.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 10s linear;
  }
}

.App-header {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
